<template>
    <div>
            <v-card elevation="2"
                    outlined
                    tile
                    class="py-5 px-5 my-2">
                <v-row>
                    <v-col cols="5">
                        <v-text-field
                            label="Nombre Segmento"
                            placeholder="agrega un nombre para el segmento"
                            v-model="dataTemplate.name"
                            outlined
                        ></v-text-field>
                    </v-col>
                    <v-col cols="5">
                        <v-select
                            :items="Clients"
                            label="Tag del segmento al que corresponde"
                            v-model="dataTemplate.client_id"
                            id="cliente-id"
                        ></v-select>
                    </v-col>
                    <v-col class="col-md-2">
                        <v-btn
                            class="ma-2"
                            text
                            icon
                            color="indigo"
                            @click="saveSegment()"
                        >
                            <v-icon> mdi-cloud-upload</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                 <v-row>
                    <v-col>
                        <br>
                        <quill-editor
                                ref="myQuillEditor"
                                v-model="dataTemplate.description"
                                :options="editorOption"
                        />
                    </v-col>
                </v-row>
           
            </v-card>
    </div>
</template>
<script>
import {misMixins} from '../../../vue/mixinsAlert';
export default {
    data(){
        return{
            segment:'',
            id : this.$route.params.id,
            editorOption: { 
            },
            Clients: [],
            dataTemplate: {
                id: 0,
                name: '',
                client_id: '',
                description:''
            },
            dimensions:''
        }
    },

    mixins:[misMixins],

    methods:{
        
        saveSegment(){

            this.LoadingOpen();

            const dimension_id = this.dataTemplate.client_id
            const name = this.dataTemplate.name
            const description = this.dataTemplate.description
            

            this.$http.post('architectures/one',{
                data:{
                    attributes:{
                        dimension_id:dimension_id,
                        name_architecture:name,
                        description:description
                    }
                }
            },{headers:{"Authorization": 'Bearer '+this.getCrypto()}}).then((response) => {

                this.LoadingClose();
                        
                let me = this
                me.$swal({
                    title: "Creado",
                    text: "segmento creado con éxito",
                    icon: 'success',
                    confirmButtonText: 'Aceptar',
                    allowOutsideClick: false,
                        }).then((result) => {
                            if (result.value) {
                                    me.$router.push({name:'Segments'})
                                }
                    })

                 }).catch((error) => {
                     this.LoadingClose();
                    
                     this.ErrorAlert('Error al crear el segmento')
                });


        },
        getDimension(){
            this.$http.get('dimensions/townhall/'+this.getClientSelect().id)
            //this.$http.get('dimensions/townhall/'+this.getClientSelect().id,{headers:{"Authorization": 'Bearer '+this.getCrypto()}})
            .then(response => {
                this.dimensions = response.body;
                console.log('dimensiones en mapas',this.dimensions)
                this.getSegments()
            }).catch(error => {
                this.ToastError(error.data.status +' '+error.data.error)
                // console.log('Error al consultar las dimensiones', error)
            })
        },
        getSegments(){
            console.log('las dimensiones',this.dimensions)
            for(var j = 0; j< this.dimensions.length; j++){
                            const value = this.dimensions[j].id
                            const text = this.dimensions[j].name_dimension 
                            const obj={
                                value: value,
                                text: text
                            }
                            this.Clients.push(obj)           
            }
                    
        }
    },
    beforeMount(){
        this.getDimension()
    }
}
</script>
